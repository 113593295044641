<template>
  <b-card title="Toko Sister List">
    <b-row class="mb-3 justify-content-between">
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="search">Search:</label>
          <input
            id="search"
            type="text"
            class="form-control"
            placeholder="Search: Name"
            v-model="filter.search"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="sortBy">Sort By:</label>
          <v-select
            id="sortBy"
            label="name"
            v-model="filter.sort_by"
            :options="sort"
            placeholder="-- Pilih --"
            :reduce="(option) => option.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="status">Status:</label>
          <v-select
            id="status"
            label="name"
            v-model="filter.status"
            :options="status"
            placeholder="-- Pilih --"
            :reduce="(option) => option.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="isSpotlight">Spotlight:</label>
          <v-select
            id="isSpotlight"
            label="name"
            v-model="filter.is_spotlight"
            :options="spotlight"
            placeholder="-- Pilih --"
            :reduce="(option) => option.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          />
        </div>
      </b-col>
    </b-row>

    <Table
      :result="result"
      :storeData="storeData"
      :is-loading="isLoading"
      :get-data="getData"
      :chooseSelected="chooseSelected"
      :current-page="currentPage"
    />
  </b-card>
</template>

<script>
import Table from "@/components/toko-sister/Table.vue";
import vSelect from "vue-select";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      result: [],
      storeData: {},
      link: "",
      filter: {},
      validations: "",
      sort: [
        { value: "most_like", name: "Most Like" },
        { value: "most_comment", name: "Most Comment" },
        { value: "latest", name: "latest" },
      ],
      spotlight: [
        { value: "true", name: "Toko Disoroti" },
        { value: "false", name: "Toko Tidak Disoroti" },
      ],
      status: [
        { value: "approved", name: "Approved" },
        { value: "need_approval", name: "Need Approval" },
        { value: "rejected_because_product", name: "Reject Because Product" },
        { value: "rejected_because_store", name: "Reject Because Product" },
        {
          value: "rejected_because_description",
          name: "Reject Because Description",
        },
      ],
      formSpotlight: {},
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData(page) {
      this.isLoading = true;
      this.currentPage = page;
      this.filter.page = page;
      this.$http
        .get("/api/v1/admin/sister-store", {
          params: this.filter,
        })
        .then((response) => {
          this.result = response.data.data.meta;
          this.storeData = response.data.data.items;
          this.currentPage = response.data.data.meta.currentPage;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    chooseSelected(slug, name) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure about this decision?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          if (name === "selected") {
            this.formSpotlight.is_selected = "true";
          } else {
            this.formSpotlight.is_favorite = "true";
          }
          this.$http
            .post(
              `/api/v1/admin/sister-store/${slug}/set-spotlight`,
              this.formSpotlight
            )
            .then((response) => {
              this.getData(this.currentPage);
              this.formSpotlight = {};
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Toko Sister berhasil di update!",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

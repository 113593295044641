<template>
  <div>
    <b-table
      striped
      hover
      :items="storeDataList"
      :fields="fields"
      :busy="isLoadingComponent"
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ (currentPageList - 1) * 10 + index + 1 }}
      </template>

      <template v-slot:cell(image)="{ item }">
        <img
          :src="item.image"
          @error="
            $event.target.src = require(`@/assets/images/avatars/no-image.png`)
          "
          class="w-100 rounded"
          style="object-fit: cover; height: 50px"
          alt=""
        />
      </template>

      <template v-slot:cell(counting)="{ item }">
        <ul class="pl-1">
          <li>{{ item.counting.like }} Likes</li>
          <li>{{ item.counting.comment }} Comments</li>
        </ul>
      </template>

      <template v-slot:cell(status)="{ item }">
        <b-badge
          pill
          variant="light-success"
          class="text-capitalize"
          v-if="item.status === 'approved'"
        >
          {{ item.status.replaceAll("_", " ").toLowerCase() }}
        </b-badge>
        <b-badge
          pill
          variant="light-warning"
          class="text-capitalize"
          v-else-if="item.status === 'need_approval'"
        >
          {{ item.status.replaceAll("_", " ").toLowerCase() }}
        </b-badge>
        <b-badge pill variant="light-danger" class="text-capitalize" v-else>
          {{ item.status.replaceAll("_", " ").toLowerCase() }}
        </b-badge>
      </template>

      <template v-slot:cell(is_selected)="{ item }">
        <b-badge
          pill
          :variant="
            item.is_selected === true ? 'light-success' : 'light-danger'
          "
          class="text-capitalize"
        >
          {{ item.is_selected === true ? "Yes" : "No" }}
        </b-badge>
      </template>

      <template v-slot:cell(is_favorite)="{ item }">
        <b-badge
          pill
          :variant="
            item.is_favorite === true ? 'light-success' : 'light-danger'
          "
          class="text-capitalize"
        >
          {{ item.is_favorite === true ? "Yes" : "No" }}
        </b-badge>
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="danger" size="sm">
          <template #button-content> Action </template>

          <b-dropdown-item :to="'/detail-toko-sister/' + item.uuid">
            <feather-icon icon="EditIcon" class="mr-75" />
            Detail
          </b-dropdown-item>
          <b-dropdown-item
            @click="chooseSelected(item.uuid, 'selected')"
            v-if="item.status === 'approved'"
          >
            <feather-icon icon="AwardIcon" class="mr-75" />
            Pilih Toko Terpilih
          </b-dropdown-item>
          <b-dropdown-item
            @click="chooseSelected(item.uuid, 'favorite')"
            v-if="item.status === 'approved'"
          >
            <feather-icon icon="StarIcon" class="mr-75" />
            Pilih Toko Favorite
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <div
      v-if="resultData.itemsPerPage > 0"
      class="mt-3 d-flex justify-content-between align-items-center"
    >
      <small
        >Showing {{ (resultData.currentPage - 1) * 10 + 1 }} to
        {{ (resultData.currentPage - 1) * 10 + 1 * resultData.itemCount }} from
        {{ resultData.totalItems }}</small
      >
      <b-pagination
        class="justify-content-end"
        v-model="currentPageList"
        :total-rows="resultData.totalItems"
        :per-page="resultData.itemsPerPage"
        aria-controls="my-table"
        @change="getData"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BTable,
  BSpinner,
  BBadge,
  BImg,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    storeData: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    editId: {
      type: "",
    },
    result: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    getData: {
      type: Function,
    },
    chooseSelected: {
      type: Function,
    },
    // chooseFavorite: {
    //   type: Function,
    // },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    storeData(value) {
      this.storeDataList = value;
    },
    currentPage(value) {
      this.currentPageList = value;
    },
    result(value) {
      this.resultData = value;
    },
  },
  data() {
    return {
      moment,
      isLoadingComponent: true,
      currentPageList: 1,
      resultData: {},
      storeDataList: [],
      fields: [
        { key: "no" },
        { key: "name" },
        { key: "image" },
        { key: "description" },
        { key: "counting", label: "Activity" },
        { key: "is_selected", label: "Toko Terpilih" },
        { key: "is_favorite", label: "Toko Terfavorit" },
        { key: "status" },
        { key: "actions" },
      ],
      //   filter: {
      //     order_type: "desc",
      //   },
    };
  },
  computed: {
    rows() {
      return this.storeDataList.length;
    },
  },
};
</script>

<style></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.storeDataList,"fields":_vm.fields,"busy":_vm.isLoadingComponent,"show-empty":"","responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(no)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s((_vm.currentPageList - 1) * 10 + index + 1)+" ")]}},{key:"cell(image)",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"w-100 rounded",staticStyle:{"object-fit":"cover","height":"50px"},attrs:{"src":item.image,"alt":""},on:{"error":function($event){$event.target.src = require("@/assets/images/avatars/no-image.png")}}})]}},{key:"cell(counting)",fn:function(ref){
var item = ref.item;
return [_c('ul',{staticClass:"pl-1"},[_c('li',[_vm._v(_vm._s(item.counting.like)+" Likes")]),_c('li',[_vm._v(_vm._s(item.counting.comment)+" Comments")])])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.status === 'approved')?_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":"light-success"}},[_vm._v(" "+_vm._s(item.status.replaceAll("_", " ").toLowerCase())+" ")]):(item.status === 'need_approval')?_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":"light-warning"}},[_vm._v(" "+_vm._s(item.status.replaceAll("_", " ").toLowerCase())+" ")]):_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":"light-danger"}},[_vm._v(" "+_vm._s(item.status.replaceAll("_", " ").toLowerCase())+" ")])]}},{key:"cell(is_selected)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":item.is_selected === true ? 'light-success' : 'light-danger'}},[_vm._v(" "+_vm._s(item.is_selected === true ? "Yes" : "No")+" ")])]}},{key:"cell(is_favorite)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":item.is_favorite === true ? 'light-success' : 'light-danger'}},[_vm._v(" "+_vm._s(item.is_favorite === true ? "Yes" : "No")+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"variant":"danger","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Action ")]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":'/detail-toko-sister/' + item.uuid}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"EditIcon"}}),_vm._v(" Detail ")],1),(item.status === 'approved')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.chooseSelected(item.uuid, 'selected')}}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"AwardIcon"}}),_vm._v(" Pilih Toko Terpilih ")],1):_vm._e(),(item.status === 'approved')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.chooseSelected(item.uuid, 'favorite')}}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_vm._v(" Pilih Toko Favorite ")],1):_vm._e()],1)]}}])}),(_vm.resultData.itemsPerPage > 0)?_c('div',{staticClass:"mt-3 d-flex justify-content-between align-items-center"},[_c('small',[_vm._v("Showing "+_vm._s((_vm.resultData.currentPage - 1) * 10 + 1)+" to "+_vm._s((_vm.resultData.currentPage - 1) * 10 + 1 * _vm.resultData.itemCount)+" from "+_vm._s(_vm.resultData.totalItems))]),_c('b-pagination',{staticClass:"justify-content-end",attrs:{"total-rows":_vm.resultData.totalItems,"per-page":_vm.resultData.itemsPerPage,"aria-controls":"my-table"},on:{"change":_vm.getData},model:{value:(_vm.currentPageList),callback:function ($$v) {_vm.currentPageList=$$v},expression:"currentPageList"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }